@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap');

.font-spaceGrotesk {
  font-family: 'Space Grotesk', sans-serif !important;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.analytics-filter-border {
  .css-w2skht-MuiAutocomplete-root .MuiAutocomplete-tag {
    font-size: 9px !important;
    padding: 0px !important;
  }
}

.contact {
  .css-w8cakf-MuiFormLabel-root-MuiInputLabel-root {
    // color: #aba291 !important;
  }
}

.no-data-img {
  .css-8kw3ph-MuiTableCell-root {
    width: 1500px !important;
  }
}

.main-sidebar {
  .css-ico5gq-MuiContainer-root {
    margin-top: 5px !important;
  }

  .css-9493y6 {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }

  .css-19r6kue-MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }

  .css-ico5gq-MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
}

.sidebarMini {
  .css-1cr5u1n-MuiStack-root {
    width: 65px !important;

    // &:hover {
    //   background-color: red;
    // }
  }
}

.stepper-title-custom {
  .css-1kt0s4l-MuiStepLabel-label {
    font-size: 12px !important;
  }

  .css-1kt0s4l-MuiStepLabel-label.Mui-active {
    font-size: 12px !important;
  }
}

.negative-text-fields {
  .css-1qw42ty-MuiInputBase-root-MuiOutlinedInput-root.Mui {
    border: #d3d3d3 !important;
  }
}

.campaign-details-active-tabs {
  background-color: #00c110 !important;
  border-radius: 2px !important;
  color: yellow !important;
}

.css-p0w5v3 {
  display: block !important;
}

.table-web {
  box-shadow:
    1px 2px 2px 0 rgba(145, 158, 171, 0.2),
    -1px 2px 2px 0 rgba(145, 158, 171, 0.2),
    /* Left shadow */ 0px -2px 2px 0 rgba(145, 158, 171, 0.2),
    /* Top shadow */ 12px 10px 24px -4px rgba(145, 158, 171, 0.12) !important;
}

.contact-website {
  ::placeholder {
    color: red;
    /* Desired color for the placeholder text */
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1mldvtj-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    width: 150px;
  }

  /* For Internet Explorer */
  :-ms-input-placeholder {
    color: red;
    /* Desired color for the placeholder text */
  }

  /* For Microsoft Edge */
  ::-ms-input-placeholder {
    color: red;
    /* Desired color for the placeholder text */
  }
}

.table-main tr {
  border-bottom: 1px solid #2a2f2c !important;
}

.table-main tr:last-child {
  border-bottom: unset !important;
}

.website .css-f9x6il-MuiToolbar-root,
.css-97ust4,
.css-pn2xxv,
.css-97ust4 {
  background-color: rgba(16, 56, 48, 0.2) !important;
}

.css-jkn71g-MuiToolbar-root,
.css-b4ugx3 {
  background-color: rgba(16, 56, 48, 0.2) !important;
}

.css-1ikgkic-MuiModal-root-MuiDrawer-root .MuiDrawer-paper,
.css-1yc5nfc .MuiDrawer-paper {
  background-color: black !important;
}

.css-w52nhq-MuiButtonBase-root-MuiListItemButton-root:before,
.css-15dz01n::before {
  display: none;
}

.css-1ajpnr9-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent !important;
}

// .MuiTypography-body2,
// .MuiTypography-root,
// .non-select,
// .css-f9f4sa-MuiTypography-root {
//   user-select: none !important;
// }
.my-masonry-grid_column {
  .css-sb65zh-MuiPaper-root-MuiAccordion-root::before {
    // display: none !important;
  }
}

.main-table-head thead,
.main-table-head tr,
.main-table-head th:first-child {
  background-color: #f1f0f0 !important;
  position: sticky;
  z-index: 100;
  background-color: white;
  max-width: 350px;
  overflow-x: auto;
}

.main-table-head-custom thead,
.main-table-head tr,
.main-table-head th:first-child {
  background-color: #333 !important;
  position: sticky;
  z-index: 100;
  background-color: white;
  max-width: 350px;
  overflow-x: auto;
}

.main-table-head th:first-child {
  background-color: #ecf0f1 !important;
  position: sticky;
  left: 0;
  z-index: 100;
  max-width: 400px;
  overflow-x: auto;
  border-radius: 0px;
}

.main-table-head-custom th:first-child {
  background-color: #333 !important;
  position: sticky;
  left: 0;
  z-index: 100;
  max-width: 400px;
  overflow-x: auto;
  border-radius: 0px;
}

.main-table-head th:first-child::-webkit-scrollbar {
  height: 150px;
}

.main-table-head-custom th:first-child::-webkit-scrollbar {
  height: 150px;
}

.main-table-head-custom th:first-child::-webkit-scrollbar-thumb {
  // background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.main-table-head th:first-child::-webkit-scrollbar-thumb {
  // background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.main-table-head::-webkit-scrollbar {
  height: 8px;
}

.main-table-head-custom::-webkit-scrollbar {
  height: 8px;
}

.main-table-head::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.main-table-head-custom::-webkit-scrollbar-thumb {
  background-color: rgba(230, 0, 0, 0.2);
  border-radius: 4px;
}

.css-1i1r27f-MuiTableCell-root {
  background-color: gray !important;
}

.arrow {
  .css-1gptlaf-MuiSvgIcon-root {
    background-color: #b2a17e33 !important;
    font-size: 50px !important;
    border-radius: 50%;
  }
}

.mini-textfield {
  .css-vb0e11-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
    font-size: 13px !important;
  }
}

.adAccountFields {
  .css-aplpb4-MuiInputBase-input-MuiOutlinedInput-input {
    width: 180px !important;
  }

  .css-zyfrhy-MuiInputBase-input-MuiOutlinedInput-input {
    width: 280px !important;
  }

  .css-feqhe6 {
    width: 280px !important;
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 180px !important;
  }
}

.css-1ned7fi-MuiStack-root {
  width: 100% !important;

  .css-1jjcu1i-MuiTabs-root {
    width: 100% !important;
  }
}

.calender-view {
  .MuiDateRangePicker-root {
    padding: 2px !important;
    font-size: 12px !important;
  }

  .css-xt61dw-MuiStack-root > .MuiTextField-root {
    min-width: 145px !important;
  }

  .css-xt61dw-MuiStack-root {
    width: 220px !important;
  }

  .css-1nni9zg {
    width: 220px !important;
    overflow: hidden !important;
  }

  .css-ghx5yn-MuiInputBase-root-MuiOutlinedInput-root {
    width: 210px !important;
    height: 37px !important;
    overflow: hidden;
    border-radius: 10px !important;
    // padding: 0px !important;
  }

  .css-clodzx {
    width: 210px !important;
    height: 37px !important;
    overflow: hidden;
    border-radius: 10px !important;
  }
}

.adAccountDropdown {
  .css-1odcuwz-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    width: 300px !important;
  }

  .css-1c31rf0 .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    width: 300px !important;
  }
}

.table-head {
  .css-nw9jna-MuiTable-root {
    width: 100% !important;
  }

  .css-2s6tmn {
    width: 100% !important;
  }
}

.table-remove-scroll {
  .css-1hckdze-MuiTable-root {
    min-width: 450px !important;
  }
}

.css-y1uzvk {
  width: 100%;
}

.css-1psf72n {
  min-width: 647px !important;
}

.table-remove-scroll-club-member {
  .css-1hckdze-MuiTable-root {
    min-width: 450px !important;
  }
}

.css-1jjcu1i-MuiTabs-root {
  width: 90% !important;
}

.css-qrq65i-MuiButtonBase-root-MuiButton-root:hover {
  color: white !important;
  background-color: black !important;
}

.css-1t1c1sm-MuiButtonBase-root-MuiButton-root {
  width: 120px !important;
}

.css-1t1c1sm-MuiButtonBase-root-MuiButton-root:hover {
  background-color: black !important;
  color: white !important;
}

.css-j7qwjs {
  width: 100% !important;
}

.custom-calender-new {
  .css-nen11g-MuiStack-root {
    width: 35% !important;
  }

  .css-j7qwjs {
    width: 35% !important;
  }
}

.shimmer-card-custom {
  .head {
    width: 150px !important;
    height: 14px !important;
  }

  .date {
    width: 225px !important;
    height: 14px !important;
  }

  .percentage {
    width: 120px !important;
    height: 14px !important;
  }

  .amount {
    width: 120px !important;
    height: 13px !important;
  }
}

.shimmer-graph-custom {
  transform: rotate(270deg) !important;

  .pointer1 {
    width: 12px !important;
    height: 14px !important;
  }

  .pointer2 {
    width: 25px !important;
    height: 14px !important;
  }

  .pointer3 {
    width: 20px !important;
    height: 14px !important;
  }

  .pointer2 {
    width: 28px !important;
    height: 13px !important;
  }

  .pointer5 {
    width: 20px !important;
    height: 13px !important;
  }

  .pointer1 {
    width: 50px !important;
    height: 13px !important;
  }

  .pointer3 {
    width: 10px !important;
    height: 13px !important;
  }

  .pointer5 {
    width: 12px !important;
    height: 13px !important;
  }
}

.campaign-create-card {
  .css-nen11g-MuiStack-root {
    width: 0% !important;
  }
}

.css-nen11g-MuiStack-root {
  width: 100% !important;
}

.wallet-address-field {
  // .css-qh1v61-MuiInputBase-root-MuiOutlinedInput-root {
  //   border: dashed !important;
  //   outline: none !important;

  //   .css-qh1v61-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused {
  //     outline: none !important;
  //   }
  // }
  .wallet-address-field {
    css-zyfrhy-MuiInputBase-input-MuiOutlinedInput-input:focus {
      border: 1px dashed blue !important;
    }
  }
}

.css-zyfrhy-MuiInputBase-input-MuiOutlinedInput-input {
  // border: 1px dashed blue !important;
}

.belowOneMinute {
  animation: heartbeat 1s infinite;
  transform-origin: center;

  &.belowFifteenSeconds {
    animation: heartbeat 0.3s infinite;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

.copy-img {
  width: 22px !important;
}

.image-contain {
  .css-3j6ntj,
  MuiBox-root.css-3j6ntj {
    object-fit: contain !important;
  }
}

.main-head-search {
  .css-1lblljk-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.no-hover-effect {
  &:hover {
    color: #1a447b !important;
    background-color: #d9deff !important;
  }
}

.my-pdf-reader {
  .my-pdf-reader-iframe {
    // display: none !important;
  }
}

.MuiFormLabel-asterisk.MuiInputLabel-asterisk,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-1ecnysf,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-4pefzv-MuiFormLabel-asterisk {
  color: red !important;
}

.hover-show-container {
  .hover-show {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .hover-show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.padding-0-container {
  padding: 0 10px !important;
}

.partner-register-container {
  // position: relative;
  padding: 0 25px;

  .partner-register {
    .register-view-container {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 996px) {
        top: 10%;
      }
    }
  }

  // .partner-register {
  //   margin-top: -30rem;
  //   width: 600px;
  //   margin-bottom: 100px;
  //   @media screen and (max-width: 1060px) {
  //     margin-top: -24rem;
  //   }
  //   @media screen and (max-width: 996px) {
  //     margin-top: -32rem;
  //   }
  //   @media screen and (max-width: 899px) {
  //     margin-top: -43rem;
  //   }
  //   @media screen and (max-width: 768px) {
  //     margin-top: -43rem;
  //     width: 100%;
  //   }

  //   @media screen and (max-width: 375px) {
  //     margin-top: -38rem;
  //   }
  // }
}

.small-select {
  .css-w8cakf-MuiFormLabel-root-MuiInputLabel-root {
    top: -5px;
  }

  .css-698ggw {
    height: 35px !important;
  }

  .css-em49yw .MuiOutlinedInput-root {
    height: 35px !important;
  }

  .css-1cw0187 {
    height: 43px !important;
  }

  .css-3wnyxb .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    height: 35px !important;
  }

  .css-3wnyxb .MuiOutlinedInput-root {
    height: 35px !important;
  }

  .MuiAutocomplete-hasPopupIcon.css-em49yw .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-em49yw .MuiOutlinedInput-root {
    height: 35px !important;
  }

  .css-fq2zi1 {
    top: -5px;
  }

  .css-4odwut-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 35px !important;
  }

  .css-1p61z7h-MuiInputBase-root-MuiOutlinedInput-root,
  .css-1qv85oo-MuiInputBase-root-MuiOutlinedInput-root {
    height: 35px;
  }

  .MuiFormControl-root {
    .css-ssonly-MuiInputBase-root-MuiOutlinedInput-root {
      height: 35px !important;
    }
  }
}

.chart-dropdown {
  .css-12geyt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f5f5f5;
  }
}

.color-danger {
  color: red !important;
}

.role-select-card {
  // background-color: #fff !important;
  transition: all ease-in-out 0.3s;

  &.inactive {
    &:hover {
      border-color: #dbe0e4 !important;
      transform: scale(1.03);
    }
  }
}

.step-wrapper {
  position: relative;
  min-height: 500px;
  margin: 0 5px;

  .stepper-button {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .step {
    transition: all ease-in-out 0.6s;
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -33rem;
    margin-top: 2rem;

    &.active {
      left: 0;
    }

    &.inactive {
    }
  }
}

#responsive-dialog-title {
  overflow: hidden;
}

.partner-create-form {
  width: 500px;
}

.modal-input {
  .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4.5px 14px !important;
  }
}

.custom-accordion {
  .css-kdwozy-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }
}

.css-12vka4q-MuiFormLabel-root-MuiInputLabel-root,
.css-1jqq78o-placeholder {
  font-size: 13px;
}

.placeholder-sm {
  &::placeholder {
    font-size: 12px; // Adjust the font size as needed
  }
}

textarea,
input {
  font-size: 12px !important;
}

.select2-selection__control {
  border-radius: 8px !important;
  border: 1px solid #e8ecee !important;
}

.custom-select-ui {
  label {
    top: -6px;
  }

  .custom-select-ui-child {
    height: 40px;
  }

  .css-1x5orh4-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px;
  }
}

// input::placeholder {
//   font-size: 12px !important;
// }

.box-shadow-unset {
  box-shadow: unset !important;
}

.partner-type-modal {
  .css-154lg22-MuiPaper-root-MuiDialog-paper {
    max-width: unset;
    min-width: 550px !important;
  }
}

.table-pd {
  .table-pd2 {
    .session-card-pd {
      width: 25px;
    }
  }
}

.session-table {
  td {
    vertical-align: top;
    padding: 8px;
  }

  tr {
    max-width: 10px;
  }
}

.cardPadding {
  .css-1j5s3cl {
    margin-bottom: 10px;
  }

  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}

.card2 {
  .css-8145p1 {
    margin-bottom: 10px;
  }

  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}

.card3 {
  .css-189e0nv {
    margin-bottom: 10px;
  }

  .css-1ludl2-MuiStack-root {
    margin-top: 15px;
  }
}

.single-view-taps {
  .single-view-tab {
    .css-heg063-MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
    }

    .css-6d3pg0-MuiTabs-indicator {
      display: none !important;
    }
  }

  #simple-tabpanel-0,
  #simple-tabpanel-1 {
    flex: 1;
  }

  .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border: 1px solid #009688;
    border-radius: 10px;
    color: #009688 !important;
  }

  .css-19kzrtu {
    padding: 0;
    padding-right: 4px !important;
  }
}

@media screen and (min-width: 1200px) {
  #sidebar-inActive {
    transition: all ease-in-out 0.3s;
    width: 65px;

    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 65px;
    }

    .css-121b4uz-MuiListItemIcon-root {
      min-width: 48px;
    }

    .css-1y6sui9 {
      width: 65px !important;
    }
  }

  #sidebar-active {
    transition: all ease-in-out 0.3s;
    width: 280px;

    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 280px;
    }
  }
}

@media screen and (max-width: 1200px) {
  #main-body-sidebar-active {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.my-new-select .css-13cymwt-control {
  border-radius: 6px;
  min-height: 40px !important;
}

.my-new-select .css-1jqq78o-placeholder {
  color: #212b36;
}

.session-update-form .css-1ifcsjq-MuiFormLabel-root-MuiInputLabel-root {
  z-index: -1 !important;
}

.my-new-select .select2-selection__menu.css-1nmdiq5-menu {
  z-index: 99;
  position: absolute;
}

.next-button.css-1umjv0b-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #fff !important;
}

.next-button.css-fkxtkf.Mui-disabled {
  color: #fff !important;
}

.div-chip {
  .div-delete {
    color: #cc3c1b !important;
    opacity: 1 !important;
    // &:hover{
    //   opacity: 1 !important;
    //   color: #CC3C1B !important;
    // }
  }
}

.react-player-video {
  iframe {
    border-radius: 12px;

    @media screen and (max-width: 2560px) {
      width: 1000px;
      height: 550px;
    }

    @media screen and (max-width: 1440px) {
      width: 720px;
      height: 450px;
    }

    @media screen and (max-width: 1024px) {
      width: 650px;
      height: 400px;
    }

    @media screen and (max-width: 768px) {
      width: 500px;
      height: 300px;
    }
  }
}

// .btn-expand:hover{
//   background-color: white;
//   color: currentColor;
// }
.terms-privacy {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.red-color-star {
  color: red;
}

.item-padding {
  padding-left: 0px !important;
}

.content-custom {
  font-size: 12px !important;
}

.currently {
  font-size: 17px;
}

.refer {
  font-size: 14px !important;
}
